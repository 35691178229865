angular.module('controllers').controller "LoginCtrl",
  ($scope, $routeParams, $cookieStore, Session, $location, $modal, $sce,
  ParamsService, IcnIdentity, $window, CustomPageTemplateService, AccountTypesService) ->
    Session.logout() if $routeParams.action == "logout"
    $scope.ssoLoginHelp = IcnIdentity.whiteLabelPartner().sso_login_help
    $scope.lessthanIE9 = -> $window.is_ie_lt9
    $scope.user = {}
    $scope.user.email = $location.search().email
    $scope.user.qps_paf = ParamsService.splitQpsPafParam()
    if IcnIdentity.isWhiteLabel()
      $scope.wlpDisclaimerHtml = $sce.trustAsHtml(IcnIdentity.whiteLabelPartner().disclaimer)
    $scope.ssoLoginEnabled = IcnIdentity.whiteLabelPartner().enable_sso_login_url
    $scope.ssoLoginUrl = IcnIdentity.whiteLabelPartner().sso_login_url
    $scope.ssoLoginName = IcnIdentity.whiteLabelPartner().sso_login_name
    $scope.useCustomSSOLink = $scope.ssoLoginEnabled && $scope.ssoLoginName && $scope.ssoLoginUrl

    $scope.wlpName = IcnIdentity.whiteLabelPartner().name
    $scope.pageContent = CustomPageTemplateService.getPageTemplate('areas/login/login/templates/', 'login')

    authError = $cookieStore.get("_icn_auth_error")
    $cookieStore.remove("_icn_auth_error")

    if authError
      $modal.open(
        templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
        controller: "customNotifyDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Session Terminated"

          msg: ->
            authError

          btn_label: -> ""
      )

    $scope.showRegister = ->
      !IcnIdentity.whiteLabelPartner().no_register

    getRole = (user_type) ->
      role = switch user_type
        when 'family_office' then 'sfo'
        when 'advisor' then 'ria'
        when 'individual' then 'investor'
        when 'institution' then 'investor'
        else ''

      return role

    $scope.redirectLogin = ->
      referralUrl = ""
      query = $location.search()
      if query and query["referral"]
        referral = encodeURIComponent(query["referral"])
        referralUrl = "referral=#{referral}"

      AccountTypesService.loadAccountTypes(IcnIdentity.whiteLabelPartner().id).then((response) ->
        if response.data.length > 1
          redirectUrl = "/account_types"
          if referral
            redirectUrl = redirectUrl  + "?" + referralUrl
        else
          id = response.data[0].id
          role = getRole(response.data[0].user_type)
          redirectUrl = "?role=#{role}&id=#{id}"
          if referral
            redirectUrl = redirectUrl + "&" + referralUrl

        $window.location.href = "/icn_react/static/register#{redirectUrl}"
      )

    $scope.shouldLoadNewLoginPage = -> 
      IcnIdentity.whiteLabelPartner().enable_new_login_page && $location.path() == '/login';
    
    # Account for soft navigations to /login
    if $scope.shouldLoadNewLoginPage()
      window.location.reload();