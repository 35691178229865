angular.module('services').factory "Session", [
  "$location"
  "$window"
  "$http"
  "$q"
  "$rootScope"
  "$timeout"
  "$cookieStore"
  "$localStorage"
  "Menu"
  "IcnIdentity"
  ($location, $window, $http, $q, $rootScope, $timeout, $cookieStore,
    $localStorage, Menu, IcnIdentity) ->
    sessionTimeout = null
    timeoutIn = 119
    timeoutPeriod = timeoutIn * 60 * 1000
    storage = $localStorage

    localStorageReset = ->
      Object.keys($localStorage).forEach (key) ->
        return if ['amlFilter', '$default', '$reset'].includes(key)

        delete $localStorage[key]

    startSessionTimer = ->
      if sessionTimeout != null
        $timeout.cancel(sessionTimeout)

      if service.currentUser != null
        unless IcnIdentity.data.env == 'development'
          sessionTimeout = $timeout(killSession, timeoutPeriod)

    killSession = ->
      service.logout('/login')

    loginParams = (email, password, devUser, rememberMe, queryString) ->
      if (devUser)
        return {
          dev_user:
            user_id: devUser
            remember_me: rememberMe
        }
      else
        return {
          user:
            email: email
            password: password
            white_label_partner_id: IcnIdentity.data.white_label_partner.id
            remember_me: rememberMe
          query_string: queryString
        }

    determineDestinationPage = (res) ->
      query = $location.search()
      referral = query["referral"] if query and query["referral"]
      referral || res.data.login_landing_page

    determineLandingPage = (res) ->
      query = $location.search()
      referral = encodeURIComponent(query["referral"]) if query and query["referral"]

      mfa_verify = '/mfa_verify'
      mfa_register = '/mfa_register'
      if referral
        mfa_register = '/mfa_register?referral=' + referral
        mfa_verify = '/mfa_verify?referral=' + referral

      if res.data.mfa_required
        return mfa_register unless res.data.phone && res.data.phone.indexOf('+') == 0
        return mfa_verify
      else determineDestinationPage(res)

    checkUserAutoEnrollmentRequirements = (res) ->
      return IcnIdentity.data.white_label_partner.auto_mfa_enrollment &&
        (!res.data.phone || res.data.phone.empty?)

    disallowUserAuthentication = (dfd) ->
      dfd.reject
        status: 401
        message: " The required credentials needed to access this site via" +
          " Two-Factor Authentication have not been provided by your partner firm." +
          " Please contact them for further details."
      $http.get("api/v1/logout").then ->
        service.currentUser = null
        localStorageReset()
        $cookieStore.remove('_icn_session')

    handleSuccess = (dfd, res) ->
      if checkUserAutoEnrollmentRequirements(res)
        disallowUserAuthentication(dfd)
      else
        storage.landingURL = determineDestinationPage(res)
        dfd.resolve { landingURL: determineLandingPage(res) }

    handleFailure = (dfd, error) ->
      if (error.data.error?) and (error.data.error.indexOf("confirm") isnt -1)
        dfd.resolve { landingURL: "/icn_react/static/register?email=" + error.config.data.user.email }
      else if (error.data.error?) and (error.data.error.indexOf("Unauthorized location") isnt -1)
        dfd.reject
          status: 401
          message: "Sorry, the application cannot be accessed from this location"
      else if (error.data.needs_password_reset == true)
        dfd.reject
          status: 423
          message: error.data?.error
          needs_password_reset: true
          reset_token: error.data.reset_token
      else
        dfd.reject
          status: error.status || 401
          message: error.data?.error || "Unknown error."

    service =
      setHeartBeat: ->
        if IcnIdentity.user && IcnIdentity.user.isSsoSessionWithLogoutStrategy
          interval = setInterval(->
            $http.get("api/v1/check_session_timeout").then((response) ->
              if (response.data.ttl <= 61)
                clearInterval(interval)
                killSession()
            ).catch(killSession)
          , 60000)

      resetSessionTimer: () ->
        startSessionTimer()

      temporaryLogin: (email, password) ->
        params =
          user:
            email: email
            password: password

          temporary_session: true

        startSessionTimer()
        $http.post "api/v1/login.json", params


      login: (email, password, devUser, rememberMe, queryString) ->
        deferred = $q.defer()

        params = loginParams(email, password, devUser, rememberMe, queryString)

        $http.post("api/v1/login.json", params
        ).then (response) ->
          handleSuccess(deferred, response)
        , (error) ->
          handleFailure(deferred, error)

        deferred.promise

      logout: (redirectTo = "dashboard") ->
        $http.get("api/v1/logout").then (res) ->
          sign_out = (redirectOnSignout = redirectTo) ->
            service.currentUser = null
            localStorageReset()
            $cookieStore.remove('_icn_session')
            $window.location.href = redirectOnSignout
            return

          if res.data.partner_behaviour
            if (res.data.partner_behaviour.strategy == 'api_call')
              $http({
                method: 'POST',
                url: res.data.partner_behaviour.url,
                headers: {
                  'X-CSRF-Token': undefined
                }
              }).then(sign_out).catch(sign_out)
            else if (res.data.partner_behaviour.strategy == 'redirect')
              sign_out(res.data.partner_behaviour.url)
          else
            sign_out()

          return
        return

      resetPassword: (password, confirmPassword, resetToken) ->
        $http.post("api/v1/registration/reset_password",
          user:
            password: password
            password_confirmation: confirmPassword
            reset_password_token: resetToken
        ,
          headers:
            "Butter-Bar": false
        )

      forgotPassword: (userEmail) ->
        $http.post("api/v1/registration/forgot_password",
          user:
            email: userEmail
        ,
          headers:
            "Butter-Bar": false
        )

# hacky shit
      devUsers: ->
        $http.get("api/v1/menu/dev_users.json", {
          headers:
            "Butter-Bar": false
        }).then (response) ->
          response.data.users

      requestCurrentUser: ->
        if service.isAuthenticated()
          $q.when service.currentUser
        else
          response = IcnIdentity.data
          service.currentUser = response.user
          service.featureFlags = response.feature_flags
          service.mainMenu = response.main_menu
          service.userMenu = response.user_menu
          if response.user
            service.cooledOff = response.user.cooled_off
            service.coolOffDaysRemaining = response.user.cooled_off_days_remaining
          service.currentUser

      featureFlags: null
      currentUser: null
      mainMenu: null
      userMenu: null
      role: null
      cooledOff: null
      coolOffDaysRemaining: null
      isAuthenticated: ->
        !!service.currentUser

    return service
]
