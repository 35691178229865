angular.module('controllers').controller 'UserManagementCtrl',
  ($scope, $http, $modal, $location, IcnIdentity, WhiteLabelPartnerService, Users, ContactCard, RegistrationAnswer,
    RolesForUserManagement, PagingService, Firms, AccountUtilities, FullWidth) ->
    $scope.unlimitedSearchResultsNumber = 1000000
    $scope.pagerName = "userManagementPager"
    $scope.perPage = 25
    $scope.page = 1
    $scope.selectedUserAdminGroups = {}
    $scope.showAdminGroups = false
    $scope.showAdminGroupFunds = false
    $scope.isShowALLAdminGroupFunds = false
    $scope.pager = PagingService.create(
      pagerName: $scope.pagerName
      pageSize: $scope.perPage
      itemCount: $scope.unlimitedSearchResultsNumber
    )
    $scope.user = IcnIdentity.user
    $scope.showCountryList = false
    $scope.countryList = []

    $scope.message =
      text: ''
      type: 'success'

    $scope.messageAlertClass = ->
      "alert alert-#{$scope.message.type}"

    $scope.nextPage = ->
      $scope.pager.nextPage($scope.pagerName)
      if $scope.search
        $scope.getUsers($scope.pager.getCurrentPage($scope.pagerName))
      else
        $scope.loadUsers($scope.pager.getCurrentPage($scope.pagerName))

    $scope.prevPage = ->
      $scope.pager.previousPage($scope.pagerName)
      if $scope.search
        $scope.getUsers($scope.pager.getCurrentPage($scope.pagerName))
      else
        $scope.loadUsers($scope.pager.getCurrentPage($scope.pagerName))

    $scope.loadUsers = (page) ->
      extra_params = ''
      if $scope.orderByField != undefined
        extra_params = "&order_field=" + $scope.orderByField + "&order_direction=" + $scope.reverseSort
      $http.get('/api/v1/users/user_management?num_users=' + $scope.perPage + "&page=" + page + extra_params)
        .then (result) ->
          $scope.usersToDisplay = result.data.users

    $scope.loadInvestorQualificationStandards = ->
      $http.get('/api/v1/investor_qualification_standard')
        .then (result) ->
          $scope.investor_qualification_standards = result.data.iqs

    $scope.startSearch = ->
      $scope.pager.reset($scope.pagerName)
      $scope.pager.itemCount($scope.unlimitedSearchResultsNumber, $scope.pagerName)
      $scope.getUsers(0)

    $scope.getUsers = (page) ->
      clearMessage()
      if $scope.orderByField != undefined
        $http(
          method: 'GET'
          url: '/api/v1/users/search'
          params:
            term: $scope.search
            page: page
            num_users: $scope.perPage
            order_field: $scope.orderByField
            order_direction: $scope.reverseSort
        )
        .then (result) ->
          $scope.usersToDisplay = result.data.users
      else
        $http(
          method: 'GET'
          url: '/api/v1/users/search'
          params:
            term: $scope.search
            page: page
            num_users: $scope.perPage
        )
        .then (result) ->
          $scope.usersToDisplay = result.data.users

    $scope.resetReason = {
      message: ''
    }

    $scope.clearSearch = ->
      $scope.search = ''
      init()

    $scope.addResetAccreditationReason = ->
      $modal.open
        templateUrl: 'templates/dialogs/reset_accreditation.html'
        windowClass: "small-modal"
        scope: $scope,
        controller: ($modalInstance, $scope) ->
          $scope.closeDialog = ->
            $modalInstance.close()

          $scope.resetAccreditation = ->
            $modalInstance.close()
            url = "/api/v1/users/#{$scope.selected.id}/registration_answers/"
            url += "#{$scope.selected.registration_answer[0].id}/reset"
            $http({
              url: url
              method: 'PATCH'
              data: $scope.resetReason
              headers: {"Content-Type": "application/json;charset=utf-8"}
            })
              .then ->
                $scope.returnToListing("Registration has been reset")

    $scope.orderBy = (field) ->
      $scope.orderByField = field
      $scope.reverseSort = !$scope.reverseSort
      page = $scope.pager.getCurrentPage($scope.pagerName)
      if $scope.search
        $scope.getUsers(page)
      else
        $scope.loadUsers(page)

    $scope.isUserAdmin = ->
      $scope.user.isUserAdmin

    $scope.crdNumber = (user) ->
      if user.firm.fm_firm_crd != null && user.firm.fm_firm_crd != ""
        user.firm.fm_firm_crd
      else
        user.answer_crd_number

    $scope.manualCrdValue = (user) ->
      if user.added_firm
        user.answer_crd_number

    $scope.viewAccreditation = (user) ->
      clearMessage()
      $scope.showAccreditation = true
      $scope.selected = user

      $scope.showRegistrationDataSection = $scope.selected.registration_answer && $scope.selected.registration_answer.length > 0

      $scope.hasInvestorRepresentativeRecord = user.investors > 0 || user.representatives > 0

    $scope.returnToListing = (msg) ->
      init()
      $scope.message.text = msg

    $scope.canEditUser = (user) ->
      $scope.isUserAdmin() && user.confirmed

    $scope.hasDelegates = (user) ->
      user.delegates && user.delegates.length > 0

    $scope.viewDelegates = (user) ->
      $modal.open(
        templateUrl: 'areas/user_management/dialogs/view_delegates.html'
        controller: "ViewDelegates"
        windowClass: 'auto-modal'
        resolve:
          selectedUser: ->
            user
      )

    $scope.hasInvestors = (user) ->
      $scope.isInvestor(user) && user.investors > 0

    $scope.viewInvestors = (user) ->
      $modal.open(
        templateUrl: 'areas/user_management/dialogs/view_investor_representative.html'
        controller: "ViewInvestorRepresentative"
        windowClass: 'large-modal'
        resolve:
          selectedUser: ->
            user
          isInvestor: ->
            true
      )

    $scope.hasRepresentatives = (user) ->
      $scope.isInvestor(user) && user.representatives > 0

    $scope.viewRepresentatives = (user) ->
      $modal.open(
        templateUrl: 'areas/user_management/dialogs/view_investor_representative.html'
        controller: "ViewInvestorRepresentative"
        windowClass: 'large-modal'
        resolve:
          selectedUser: ->
            user
          isInvestor: ->
            false
      )

    $scope.hasAdminGroups = (adminGroups) ->
      Object.keys(adminGroups).length != 0

    $scope.viewAdminGruops = (selectedUser) ->
      $scope.selectedUser = selectedUser
      $scope.massagedSelectedUser = massageSelectedUserData(selectedUser)
      $scope.showAdminGroups = true

    massageSelectedUserData = (selectedUser) ->
      selectedUser.massaged_admin_groups_data = {}
      selectedUser.massaged_admin_groups_funds_data = {}
      for key, value of selectedUser.admin_groups
        totalNumber = 0
        selectedUser.massaged_admin_groups_funds_data[key] = {}
        for i, j of value
          totalNumber += 1
          selectedUser.massaged_admin_groups_funds_data[key][i] = {fund_name: j, co_exist_admin_group: []}
        selectedUser.massaged_admin_groups_data[key] = totalNumber
      selectedUser

    $scope.viewSelectedAdminGroupFunds = (selectedAdminGroupName, adminGroupFundsForUser) ->
      $scope.adminGroupFundsForUser = []
      angular.forEach adminGroupFundsForUser, (value, key) ->
        $scope.adminGroupFundsForUser.push({
          paf_id: key,
          fund_name: value.fund_name,
          co_exist_admin_group: []
        })
      $scope.showAdminGroupFunds = true
      $scope.isShowALLAdminGroupFunds = false
      $scope.showAdminGroups = false
      $scope.adminGroupFundsPageTitle = selectedAdminGroupName

    $scope.viewAllAdminGroupFunds = (massaged_admin_groups_funds_data) ->
      $scope.adminGroupFundsPageTitle = 'All Funds'
      $scope.showAdminGroupFunds = true
      $scope.isShowALLAdminGroupFunds = true
      $scope.showAdminGroups = false
      $scope.adminGroupFundsForUser = []
      massageShowAllAdminGroupFundsData(massaged_admin_groups_funds_data)

    massageShowAllAdminGroupFundsData = (massaged_admin_groups_funds_data) ->
      allFundsObject = {}
      angular.forEach massaged_admin_groups_funds_data, (value, key) ->
        angular.forEach value, (v, k) ->
          if !allFundsObject.hasOwnProperty(k)
            allFundsObject[k] = v
          allFundsObject[k].co_exist_admin_group.push(key)

      angular.forEach allFundsObject, (value, key) ->
        $scope.adminGroupFundsForUser.push({
          paf_id: key,
          fund_name: value.fund_name,
          co_exist_admin_group: value.co_exist_admin_group
        })

    $scope.returnToAdminGroups = ->
      $scope.showAdminGroupFunds = false
      $scope.showAdminGroups = true
      $scope.isShowALLAdminGroupFunds = false

    $scope.editUser = (user) ->
      clearMessage()

      # Show all investor qualification standards and check the ones the user has
      angular.forEach($scope.investor_qualification_standards, (value, index) ->
        $scope.investor_qualification_standards[index].check = user.user_investor_qualification_standard_ids.indexOf(value.id) >= 0
      )

      $scope.showEditForm = true
      $scope.userToEdit = user
      $scope.originalEmail = user.email
      $scope.emailNotUnique = false
      $scope.passwordDoesntMatch = false
      $scope.hasInvestorRepresentativeRecord = user.investors > 0 || user.representatives > 0

    clearMessage = ->
      $scope.message.text = ''
      $scope.message.type = 'success'

    displayError = (message) ->
      $scope.message.text = message
      $scope.message.type = 'danger'

    $scope.updateUser = (user) ->
      wlp_id = if user.white_label_partner then user.white_label_partner.id else 0
      iqs_ids = $scope.investor_qualification_standards.filter((iqs) -> iqs.check).map((iqs) -> iqs.id)
      user.disabled_reason = '' unless user.disabled
      if $scope.isInvestor(user)
        user.accredit_role = 'investor'
      if $scope.userIsRiaWithAccreditation(user)
        if user.accredit && user.firm && user.firm.name == "RIA"
          displayError('You must add a firm to the user before you can accredit the user')
        else
          Users.update(user.id, user.first_name, user.last_name, user.email, user.password, user.password_confirmation,
                       user.disabled, user.disabled_reason, wlp_id, user.cooled_off_at, user.accredit,
                       user.accredit_role, user.send_welcome, iqs_ids,
                       user.firm_id, user.needs_password_reset, user.country?.name,
                       user.answer_firm_name, user.answer_crd_number, user.answer_advisor_crd_number).then((result) ->
            init()
            $scope.showCountryList = false
            $scope.message.text = 'User has been updated'
          ).catch (err) ->
            displayError(err.data.error) if err && err.data
      else
        Users.update(user.id, user.first_name, user.last_name, user.email, user.password, user.password_confirmation,
                     user.disabled, user.disabled_reason, wlp_id, user.cooled_off_at, user.accredit, user.accredit_role,
                     user.send_welcome, iqs_ids, user.firm_id, user.needs_password_reset, user.country?.name).then((result) ->
          init()
          $scope.showCountryList = false
          $scope.message.text = 'User has been updated'
        )

    $scope.validDate = ->
      format = /^\d{2}\/\d{2}\/\d{4}$/
      if $scope.userToEdit && $scope.userToEdit.cooled_off_at
        !!$scope.userToEdit.cooled_off_at.match(format)
      else
        false

    $scope.emailUnique = (email, wlpId) ->
      ContactCard.check_email_unique(email: email, wlp_id: wlpId).$promise.then (result) ->
        $scope.emailNotUnique = false
        if !result.unique && email != $scope.originalEmail
          $scope.emailNotUnique = true

    $scope.passwordMatch = (password, passwordConfirmation) ->
      if !AccountUtilities.passwordRequirementsAllCompleted(password, passwordConfirmation)
        $scope.passwordDoesntMatch = true
      else
        $scope.passwordDoesntMatch = false

    $scope.invalidForm = ->
      !$scope.validDate() || !$scope.disabledUpdateButton || $scope.emailNotUnique ||
      !$scope.hideFirmAutocompleteError() ||
      ($scope.userEditForm && !$scope.userEditForm.$valid) || !$scope.validAccreditation() ||
      $scope.passwordDoesntMatch || ($scope.showCountryList && !$scope.userToEdit.country)

    $scope.validAccreditation = ->
      return true unless $scope.userToEdit.accredit

      if $scope.isQpUnaccredited($scope.userToEdit)
        (!!$scope.userToEdit.send_welcome && !!$scope.userToEdit.accredit_role)
      else
        !!$scope.userToEdit.send_welcome

    userRequireCountryForAccreditation = ->
      $scope.userToEdit.roles.some((role) -> ['ai_default','qp_default','ria_default','sfo_default'].includes(role))

    # Show dropdown with list of countries
    showCountryOptions = ->
      $scope.showCountryList = true
      RegistrationAnswer.options().then (d) ->
        $scope.countryList = d.countries
      displayError('Please select a country before approving the user in order for the user to be shown investment opportunities.')

    # Check if registration_answers has 'country'
    checkRegistrationAnswers = ->
      if ($scope.userToEdit.registration_answer.length == 0 ||
        (Object.keys($scope.userToEdit.registration_answer[0].answers).length == 0) ||
        $scope.userToEdit.registration_answer[0].answers['country'] == undefined ||
        $scope.userToEdit.registration_answer[0].answers['country'] == null ||
        $scope.userToEdit.registration_answer[0].answers['country'] == '')

          showCountryOptions()

    $scope.clearAccreditationFields = ->
      $scope.userToEdit.accredit_role = null
      $scope.userToEdit.send_welcome = null
      # Check if registration_answers has 'country'
      if userRequireCountryForAccreditation()
        checkRegistrationAnswers()

    $scope.firmsForRegistration = (query) ->
      Firms.firmsForRegistrationAutocomplete(
        query
      ).then (data) ->
        $scope.firms = data.firms

    $scope.hideFirmAutocompleteError = ->
      # Hide the error if the input is filled in with a choice from the given user firms
      noFirmBlur = $scope.formBlur.firm_blur isnt true
      noFirmBlur || validFirmChosen()

    validFirmChosen = ->
      _.any($scope.firms, (k) -> k.name == $scope.userToEdit.firm.name)

    $scope.selectRecord = ($item) ->
      $scope.userToEdit.advisor_firm_id = $item.id
      $scope.userToEdit.advisor_firm_name = $item.name
      $scope.currentFirmCrd = $item.crd

    $scope.selectFirmRecord = ($item) ->
      $scope.userToEdit.firm_id = $item.id
      $scope.userToEdit.firm.fm_firm_crd = $item.fm_firm_crd

    $scope.userIsRiaWithAccreditation = (user) ->
      user && user.is_ria && !!user.registration_answer

    $scope.editReferralCodes = (user) ->
      $location.path("/user_management/#{user.id}/referral_codes")

    $scope.editAccessFunds = (user) ->
      $location.path("/user_management/#{user.id}/private_access_funds")

    $scope.rolesForManagement = ->
      RolesForUserManagement.rolesForManagement()

    $scope.unlockAccount = (user) ->
      Users.unlockAccount(user.id).$promise.then (response) ->
        init()
        $scope.message.text = 'User account has been unlocked'

    $scope.allowedProfile = (user) ->
      if user
        user.is_ria or user.is_qp or user.is_sfo

    $scope.isQp = (user) ->
      if user
        _.some user.roles, (role) -> role == 'qp_default'

    $scope.isInvestor = (user) ->
      if user
        _.some user.roles, (role) -> role == 'investor'

    $scope.isQpUnaccredited = (user) ->
      user && !user.accredited && $scope.isQp(user)

    $scope.isNonQpUnaccredited = (user) ->
      user && !user.accredited && !$scope.isQp(user)

    $scope.showAccreditUser = (user) ->
      ($scope.isNonQpUnaccredited(user) && (user.user_accreditation || user.registration_answer)) || $scope.isQpUnaccredited(user)

    init = ->
      $scope.allowAccreditationReset = IcnIdentity.featureFlagEnabled('enable_accreditation_reset')
      if $scope.search then $scope.getUsers($scope.search) else $scope.loadUsers(0)
      $scope.loadInvestorQualificationStandards()
      clearMessage()
      $scope.showAccreditation = false
      $scope.showAdminGroups = false
      $scope.showAdminGroupFunds = false
      $scope.isShowALLAdminGroupFunds = false
      $scope.showEditForm = false
      $scope.orderByField = 'id'
      $scope.reverseSort = true
      $scope.formBlur = {}
      $scope.disabledUpdateButton = true

    $scope.$watch 'userToEdit.password', (newVal) ->
      if newVal == undefined ||
         newVal == "" ||
         (newVal && AccountUtilities.validatePasswordRegex(newVal))
        $scope.disabledUpdateButton = true
      else $scope.disabledUpdateButton = false
      $scope.invalidForm()

    $scope.$watch 'userToEdit.passwordConfirmation', (newVal) ->
      if newVal == undefined ||
         newVal == "" ||
         (newVal && AccountUtilities.validatePasswordRegex(newVal))
        $scope.disabledUpdateButton = true
      else $scope.disabledUpdateButton = false
      $scope.invalidForm()

    FullWidth.useFullWidthScreen()

    $scope.$on '$destroy', ->
      FullWidth.removeFullWidthScreen()

    init()
