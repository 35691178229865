angular.module('directives').directive 'pafListItem',
  ($modal, $location, InvestmentDetailRouter, Hits, IcnIdentity,
    Dialog, InvestorProfileService, NonDisclosureAgreement) ->
      restrict: 'E'
      templateUrl: 'directives/paf_list_item/paf_list_item.html'
      scope:
        fundId: "="
        name: "="
        manager: "="
        displayStrategy: "="
        detailsHidden: "="
        limitedForUser: "="
        firmLogoUrl: "="
        fundLogoUrl: "="
        multiManagerFund: "="
        paf: "="

      link: (scope, element, attrs) ->
        combineDisclaimerTexts = (texts) ->
          texts.join("<br><br>")

        buildDisclaimerModalObj = (modals) ->
          if shouldBuildDisclaimer()
            modals.push({
              disclaimerText: [scope.paf.view_disclaimer],
              header: "User Agreement",
              acknowledgeText: "I acknowledge that I have read and agree to the above terms",
            })
          return modals

        buildNDAModalObj = (modals) ->
          if shouldShowNDAModal()
            modals.push({
              disclaimerText: '',
              header: "Non-Disclosure Agreement",
              acknowledgeText: "I acknowledge and agree that I have read and agree to the above terms",
            })

          return modals

        shouldBuildDisclaimer = ->
          return scope.paf.view_disclaimer? &&
                 scope.paf.view_disclaimer != "" &&
                 IcnIdentity.user.seen_fund_disclaimers.indexOf(scope.fundId) == -1

        shouldShowNDAModal = ->
          scope.paf.gp_firm_name == 'iCapital Network' && !scope.paf.suppress_nda &&
            (!scope.nonDisclosureAgreements[0] || !scope.nonDisclosureAgreements[0]?.agreement)

        relevantIcnGeoCountryIds = ->
          if scope.paf && scope.paf.icn_geo_country_ids
            scope.paf.icn_geo_country_ids
          else
            []

        scope.viewFundProfile = (event) ->
          event.preventDefault() if event
          showDisclaimerModal ->
            $location.path("/funds/#{scope.fundId}")

        scope.viewInvestmentDetails = (event) ->
          event.preventDefault() if event
          showDisclaimerModal ->
            Hits.create 'invite button clicked', 'Fund', scope.fundId
            if !IcnIdentity.isWhitelistedForPaf(scope.paf.id) && (scope.limitedForUser || scope.detailsHidden)
              default_text = 'Investment subscription documents will be available soon.' +
                '<BR>' +
                'Please click "View Profile" to review the details of this investment opportunity.'

              disabled_for_investment_text = scope.paf.disabled_for_investment_text || default_text
              Dialog.openNotifyDialog("Investment Details", disabled_for_investment_text, false)
            else
              concludeInviteInvestor()

        scope.viewInvestmentDetailsText = InvestmentDetailRouter.shortButtonText()

        isRiaOrBranchManager = IcnIdentity.isRiaOrBranchManager()

        concludeInviteInvestor = ->
          InvestorProfileService.current_user_has_profile(scope.paf.id).$promise.then (resp) ->
            if resp.has_profile || (IcnIdentity.canAddNewInvestors() && IcnIdentity.canCreateNewProfile())
              InvestmentDetailRouter.perform(scope.paf)
            else
              showCannotInviteInvestor()

        canCreateInvestorOrProspectInvestments = ->
          IcnIdentity.canAddNewInvestors() || IcnIdentity.canInviteSavedInvestors() ||
          IcnIdentity.canAddExistingProspectInvestments() || IcnIdentity.canAddNewProspectInvestments()

        scope.showInvest = ->
          return false unless scope.paf?.id

          if isRiaOrBranchManager
            canCreateInvestorOrProspectInvestments()
          else if IcnIdentity.isIndividualInvestor()
            IcnIdentity.canAddNewInvestors()
          else if IcnIdentity.isNonRiaInvestor()
            true

        scope.logoUrl = ->
          if scope.fundLogoUrl == ""
            scope.firmLogoUrl
          else
            scope.fundLogoUrl

        showCannotInviteInvestor = ->
          cannot_invite_investor_text = "You do not have any pre-certified Investors eligible to invest in this fund."
          $modal.open(
            templateUrl: "dialogs/custom_notify_dialog/custom_notify_no_indentation_dialog.html"
            controller: "customNotifyDialogCtrl"
            windowClass: "small-modal"
            resolve:
              header: -> "Create Investment"
              msg: -> cannot_invite_investor_text
              btn_label: -> "OK"
          )
