angular.module('controllers').controller "FundDisclaimerAcceptDialogCtrl",
  ($scope, $modalInstance, $q, fundId, privateAccessFundId, fundName,
   Funds, disclaimers, IcnIdentity, NonDisclosureAgreement) ->
     activeDisclaimerIdx = null

     setDisclaimerDefaults = ->
       $scope.disclaimers = disclaimers.map (disclaimer) ->
         obj = Object.assign({}, disclaimer)
         obj.tempAccepted = false
         obj.nonDisclosureAgreement = true if disclaimer.header == "Non-Disclosure Agreement"
         obj.fundName = fundName
         obj

     initializeModal = ->
       setDisclaimerDefaults()

       $scope.currentIdx = 0
       activeDisclaimerIdx = 0
       $scope.currentDisclaimer = $scope.disclaimers[$scope.currentIdx]

       $scope.toggleAcceptance = ->
         return if $scope.currentDisclaimer.accepted
         $scope.currentDisclaimer.tempAccepted = !$scope.currentDisclaimer.tempAccepted

       $scope.clickable = (index) ->
         index != $scope.currentIdx &&
         ($scope.disclaimers[index].accepted || activeDisclaimerIdx == index)

       $scope.navigate = (index) ->
         $scope.currentIdx = index
         $scope.currentDisclaimer = $scope.disclaimers[$scope.currentIdx]

       $scope.advance = ->
         if $scope.currentDisclaimer.accepted
           $scope.navigate($scope.currentIdx + 1)
         else
           $scope.currentDisclaimer.accepted = true

           if $scope.currentDisclaimer.header == "User Agreement"
             Funds.acceptFundDisclaimer(fundId).then ->
               IcnIdentity.user.seen_fund_disclaimers.push(fundId)
               advanceModal()
           else if $scope.currentDisclaimer.header == "Non-Disclosure Agreement"
             NonDisclosureAgreement.create(privateAccessFundId).then ->
               advanceModal()

       $scope.cancel = ->
         $modalInstance.dismiss()

     lastDisclaimer = ->
       $scope.currentIdx >= $scope.disclaimers.length - 1

     advanceModal = ->
       if lastDisclaimer()
         $modalInstance.close()
       else
         activeDisclaimerIdx += 1
         $scope.navigate($scope.currentIdx + 1)

     if disclaimers.length > 0
       initializeModal()
