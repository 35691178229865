angular.module('services').factory 'RoutesUtilService', ($location, $route, $rootScope, IcnIdentity) ->
  #Paths must match, routes.js
  LoginPath = '/login/:referral?'
  LoginUnlockedPath = '/login/unlocked'
  ForgotPasswordPath = '/forgot_password?'
  ResetPasswordPath = '/reset_password?'

  LogoutPath = '/logout'

  RegisterPath = '/icn_react/static/register?'

  DisclosuresPath = '/icn_react/static/disclosures'

  MfaRegistrationPath = '/mfa_register'
  MfaVerifyPath = '/mfa_verify/?'

  FundsDetailPath = '/funds/:id'
  PrivateEquityFundsPath = '/private_equity_funds'
  HedgeFundsPath = '/hedge_funds'
  AIFundPath =  '/ai_funds'

  PafPEFundsPath = '/paf_admin/pe/funds/:private_access_fund_id?'
  PadHFFundsPage = '/paf_admin/hf/funds/:private_access_fund_id?'

  onPublicPage = ->
    $rootScope.currentRoute.isPublic

  onLoggedOutOnlyPage = ->
    originalPathIsAny [
      LoginPath
      LoginUnlockedPath
      RegisterPath
      ResetPasswordPath
      ForgotPasswordPath
    ]

  onMfaRegistrationPage = ->
    originalPathIsAny [
      MfaRegistrationPath
      MfaVerifyPath
      LogoutPath
    ]

  onAnyFundPage = ->
    originalPathIsAny [
      FundsDetailPath
      PrivateEquityFundsPath
      HedgeFundsPath
      AIFundPath
    ]

  originalPathIsAny = (paths) ->
    _.any paths, originalPathIs

  originalPathIs = (path) ->
    $rootScope.currentRoute.originalPath is path

  onRootPage = ->
    $location.path() is '/'

  onPrivateEquityFundsPage = ->
    $rootScope.currentRoute.originalPath is PafPEFundsPath

  onHedgeFundsPage = ->
    $rootScope.currentRoute.originalPath is PadHFFundsPage

  isReactPage = (url) ->
    reactRoutes = _.filter($route.routes, (route) -> route.isReactPage)
    _.any(reactRoutes, (route) -> url.match(route.regexp))

  onReactPage = ->
    !!$route?.current?.$$route?.isReactPage

  impersonationBlocked = ->
    return false unless IcnIdentity.user.is_imposter
    $rootScope.currentRoute.impersonationBlocked

  return {
    impersonationBlocked: impersonationBlocked
    onPublicPage: onPublicPage
    onRootPage: onRootPage
    onAnyFundPage: onAnyFundPage
    onLoggedOutOnlyPage: onLoggedOutOnlyPage
    onPrivateEquityFundsPage: onPrivateEquityFundsPage
    onHedgeFundsPage: onHedgeFundsPage
    onMfaRegistrationPage: onMfaRegistrationPage
    isReactPage: isReactPage
    onReactPage: onReactPage
  }
