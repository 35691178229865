angular.module('controllers').controller "MainCtrl",
  ($scope, $rootScope, $cookies, $dialogs, $location, $sce,
  Session, IcnIdentity, RoutesUtilService) ->

    $scope.icn_identity = IcnIdentity
    $rootScope.messages = unread_inbox: {}
    $rootScope.auth = Session
    $rootScope.auth.requestCurrentUser()
    $scope.widerFooter = false
    $scope.widerContainer = false
    $scope.needAssistanceOverride = $sce.trustAsHtml(IcnIdentity.needAssistanceOverride())
    $rootScope.auth.setHeartBeat()
    $scope.currentdate = new Date()

    $scope.environmentLabel = ->
      $scope.icn_identity.environment()

    $scope.thirdPartyJsDisabled = ->
      $scope.icn_identity.data.third_party_js_off

    loadHoneyBadgerScript = ->
      script = document.createElement('script')
      script.src = "//js.honeybadger.io/v0.2/honeybadger.min.js"
      script.async = false
      document.head.appendChild(script)
      new Promise (resolve, reject) ->
        script.onload = resolve

    initHoneybadger = ->
      env = $scope.environmentLabel()
      Honeybadger.configure {
        api_key: $scope.icn_identity.data.honeybadger_api_key,
        environment: $scope.environmentLabel(),
        disabled: env == 'test' || env == 'development',
        onerror: true,
      }

    if !$scope.thirdPartyJsDisabled()
      loadHoneyBadgerScript().then (result) ->
        initHoneybadger()

    $("body").on "showTooltip", "[tooltip]", ->
      unless $("body").is(".tooltips-disabled")
        $cookies["tooltipCount"] = JSON.stringify({})  unless $cookies["tooltipCount"]
        tooltipCount = parseInt(JSON.parse($cookies["tooltipCount"]), 10) or 0
        tooltipCount += 1
        $cookies["tooltipCount"] = JSON.stringify(tooltipCount)
        if tooltipCount is 50
          $dialogs.notify "Tip", "You can easily show and hide tool tips by using the menu on your user account."
        $("body").off "showTooltip"  if tooltipCount >= 50

    $scope.loggedIn = ->
      IcnIdentity.authenticated()

    onPublicFacingPage = ->
      isLoggedOutPage = RoutesUtilService.onLoggedOutOnlyPage()
      onRoot = RoutesUtilService.onRootPage()
      isPublic = RoutesUtilService.onPublicPage() and !$scope.loggedIn()

      isLoggedOutPage or onRoot or isPublic

    onFundProfile = ->
      /\/funds\/\d+/.exec($location.path())?

    $scope.containerFullWidth = ->
      IcnIdentity.data.white_label_partner.slug is 'ieqc'

    $scope.isMainSite = ->
      IcnIdentity.data.white_label_partner.id is 0

    $scope.getBodyCSS = ->
      if $scope.routeLoading
        ''
      else if $scope.isMainSite()
        "#{getMainSiteBodyCSS()} #{$scope.environmentLabel()}"
      else
        "#{getWLPBodyCSS()} #{$scope.environmentLabel()}"

    $scope.getEnvironmentLabel = ->
      env = $scope.environmentLabel()
      env if env != 'production'

    $scope.bannerText = ->
      IcnIdentity.data.white_label_partner.banner?.message

    $scope.getBannerTextColSize = ->
      banner_width = IcnIdentity.data.white_label_partner.banner?.message_content_width
      banner_width ?= 5
      banner_width

    $scope.getBannerBackgroundInlineStyles = ->
      IcnIdentity.data.white_label_partner.banner?.banner_background_inline_styles

    $scope.getGoogleTagManagerId = ->
      env = $scope.environmentLabel()
      if env == 'production'
        return 'https://www.googletagmanager.com/ns.html?id=GTM-NZQNJCV'
      if env == 'feature_staging'
        return 'https://www.googletagmanager.com/ns.html?id=GTM-TDQK2D7'
      if env == 'staging'
        return 'https://www.googletagmanager.com/ns.html?id=GTM-NB4WGDQ'

    $scope.userDisplayName = ->
      $scope.icn_identity.data.user.name

    $scope.currentlyImpersonating = ->
      $scope.icn_identity.data.user.is_imposter

    $scope.showFeedbackLink = ->
      $scope.icn_identity.data.show_feedback_link

    getMainSiteBodyCSS = ->
      if onPublicFacingPage()
        "body-public"
      else if $scope.currentlyImpersonating()
        "body-impersonating"
      else
        "body-registered"

    getWLPBodyCSS = ->
      wlp_custom_class = IcnIdentity.whiteLabelPartner().custom_css_class
      wlp_custom_class ||= ''

      if onPublicFacingPage()
        "body-wlp " + wlp_custom_class
      else if $scope.currentlyImpersonating()
        "body-impersonating " + wlp_custom_class
      else
        "body-registered " + wlp_custom_class

    $scope.checkDisclaimerSize = (newUrl) ->
      $scope.widerFooter = wideUrl(newUrl, ['client_management', 'investor_profile', 'pe/subscriptions'])
      $scope.widerContainer = wideUrl(newUrl, ['login']) && $scope.containerFullWidth()

    $scope.showFooterList = ->
      $scope.isMainSite() && $location.search().sso != 'true'

    $scope.showThirdPartyVendors = ->
      IcnIdentity.authenticated() && !IcnIdentity.hideThirdPartyVendors()

    wideUrl = (newUrl, arr) ->
      arr.some (string) ->
        (newUrl.search(string) > -1)

    $scope.routeLoading = true

    $scope.getTermsOfUseHref = ->
      if (!$scope.isMainSite() && IcnIdentity.data.white_label_partner.terms_of_use?.enable_custom_wlp_page_and_popup)
        if $scope.loggedIn() then 'custom_terms_of_use' else 'icn_react/static/custom_terms_of_use'
      else
        'https://www.icapitalnetwork.com/terms-of-use/'

    $scope.getPrivacyPolicyHref = ->
      if (!$scope.isMainSite() && (Object.keys(IcnIdentity.data.white_label_partner.privacy_policy).length > 0))
        if $scope.loggedIn() then 'privacy_policy' else 'icn_react/static/privacy_policy'
      else
        'https://www.icapitalnetwork.com/privacy-policy/'

    initUserSnap = ->
      s = document.createElement("script")
      s.type = "text/javascript"
      s.async = true
      s.src = '//api.usersnap.com/load/bf3459c0-659b-4a49-aae2-54c47f3fe9b1.js'
      x = document.getElementsByTagName('head')[0]
      x.appendChild(s)

    if ($scope.environmentLabel() != 'development' && $scope.environmentLabel() != 'test' && !$scope.thirdPartyJsDisabled() && $scope.showFeedbackLink())
      initUserSnap()

    $rootScope.$watch 'routeLoading', ->
      $scope.routeLoading = $rootScope.routeLoading

    $rootScope.$watch "currentUrl", (event) ->
      if($rootScope.currentUrl != null)
        $scope.routeLoading = $rootScope.routeLoading
        $scope.publicPage = onPublicFacingPage()
        $scope.onFundProfile = onFundProfile()
        $scope.bodyCSS = $scope.getBodyCSS()
        $scope.publicMainLogo = onPublicFacingPage() && !IcnIdentity.isWhiteLabel()
        $scope.publicWLPLogo = onPublicFacingPage() && IcnIdentity.isWhiteLabel()
        $scope.accredited = IcnIdentity.accredited()

        $("html,body").scrollTop 0
        $scope.checkDisclaimerSize($rootScope.currentUrl)
