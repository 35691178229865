angular.module('services').service "ExportsService", ($resource) ->
  resource = $resource('/api/v1/exports',
    private_access_fund_id: "@paf_id",
  ,
    all:
      method: "GET"
    create:
      method: "POST"
    privateAccessFunds:
      url: '/api/v1/exports/private_access_funds'
      method: "GET"
      isArray: true
  )

  all = (pafId, page = 1, range = 30) ->
    resource.all(
      private_access_fund_id: pafId
      page: page
      range: range
    ).$promise

  privateAccessFunds = ->
    resource.privateAccessFunds().$promise

  exportInvestments = (pafId, userId = null) ->
    resource.create(
      export_type: 'investment_export'
      private_access_fund_id: pafId,
      ria_id: userId
    ).$promise

  exportSubscriptionDocuments = (pafId, status) ->
    resource.create(
      export_type: 'subscription_document_export'
      private_access_fund_id: pafId
      filter: status
    ).$promise

  exportApprovalHistory = (pafId) ->
    resource.create(
      export_type: 'approval_history_export'
      private_access_fund_id: pafId,
    ).$promise

  all: all
  privateAccessFunds: privateAccessFunds
  exportInvestments: exportInvestments
  exportSubscriptionDocuments: exportSubscriptionDocuments
  exportApprovalHistory: exportApprovalHistory
