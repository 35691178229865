angular.module "icn.paf_admin.components"
  .factory 'PafAdminNavSvc', (IcnIdentity) ->
    self = @

    self.selectedFundId = null
    self.currentPath = ''

    self.navItems = (type) ->
      @type = type

      _.map self.getBaseNavItems(), (item) ->
        item.path = item.url
        if type != 'compliance'
          item.path += self.getSelectedFundPath()
        item.active = self.isActive(item.path)
        return item

    self.getBaseNavItems = ->
      items = []

      if IcnIdentity.isUserManager()
        if @type == 'user_management'
          items = [
            {
              label: 'User Management'
              url: '/user_management'
            },
            {
              label: 'User Export'
              url: '/user_management/user_export'
            },
            {
              label: 'Reverse Solicitation'
              url: '/user_management/reverse_solicitation'
            },
          ]

      if IcnIdentity.isAdminOrIr() || IcnIdentity.isHomeOffice()
        if @type == 'investor_relations'
          items = []
          if IcnIdentity.check('canManageInvestors')
            items.push {label: 'Investor Search', url: '/icn_react/static/investor_relations/investor_search'}

          if IcnIdentity.check('canSendMassEmails')
            items.push {label: 'Mass Emails', url: '/investor_relations/mass_emails'}

          if IcnIdentity.check('canViewIrResponsibilityMatrix')
            items.push { label: 'Subscription Reviewer', url: '/investor_relations/subscription_reviewer'}

          if IcnIdentity.check('canViewSubscriptionWorkflow') || IcnIdentity.check('canManageSubscriptionWorkflow')
            items.push { label: 'Workflow Manager', url: '/subscription_workflow_status' }
        else if @type == 'compliance'
          items = [
              {
                label: 'Investor Search'
                url: "/compliance/investor_search"
              },
              {
                label: 'Compliance Reporting'
                url: "/compliance/compliance_reporting"
              },
              {
                label: 'Form PF Classification'
                url: '/compliance/form_pf_classification'
              }
            ]

      else if IcnIdentity.isAmlApprover()
        if @type == 'compliance'
          items = [
              {
                label: 'Investor Search'
                url: "/compliance/investor_search"
              },
              {
                label: 'Compliance Reporting'
                url: "/compliance/compliance_reporting"
              },
              {
                label: 'Form PF Classification'
                url: '/compliance/form_pf_classification'
              }
            ]

      return items

    self.getSelectedFundPath = () ->
      if !_.isUndefined self.selectedFundId
        return '/' + self.selectedFundId
      else
        return ''

    self.isActive = (path) ->
      if @currentPath
        if @currentPath[@currentPath.length - 1] == '/'
          @currentPath[0...-1] == path
        else
          @currentPath == path

    return @
