angular.module('controllers').controller 'UserManagementUserExportCtrl',
  ($scope, WhiteLabelPartnerService, FormDataService, RolesForUserManagement, RegistrationAnswer,
      UserManagementService, $window, FullWidth) ->
    $scope.searchParams = {}
    $scope.accreditationOptions = ['unaccredited', 'accredited']

    $scope.getWhiteLabelPartners = ->
      WhiteLabelPartnerService.query().then (results) ->
          $scope.whiteLabelPartners = [{id: 0, name: 'iCapital Network'}]
          $scope.whiteLabelPartners = $scope.whiteLabelPartners.concat results

    $scope.rolesForManagement = ->
      ['All'].concat RolesForUserManagement.rolesForManagement()

    $scope.getAccreditationOptions = ->
      RegistrationAnswer.options().then (d) ->
        $scope.countryList = d.countries
        $scope.titleOptions = d.title_options
        $scope.employmentOptions = d.employment_options
        $scope.incomeOptions = d.income_options
        $scope.netWorthOptions = ['All'].concat d.net_worth_options
        $scope.investmentValueOptions = ['All'].concat d.investment_value_options
        $scope.firmInvestmentValueOptions = d.firm_investment_value_options
        $scope.firmOptions = ['All'].concat d.firm_options
        $scope.mailDomainOptions = ['All'].concat d.mail_domain_options

    $scope.userExport = ->
      _.each($scope.searchParams, (v, k) ->
        delete $scope.searchParams[k] if !v
        if Array.isArray(v)
          if _.find(v, (i) ->
            i == 'All'
          )
            delete $scope.searchParams[k]
      )
      $scope.job_submission_status = null
      $scope.showExportErrorMessage = false

      has_WLP_selection = !!$scope.searchParams['white_label_partner']
      has_firm_selection = !!($scope.searchParams['firm_options'] &&
        $scope.searchParams['firm_options'] != 'All')
      has_domain_selection = !!($scope.searchParams['mail_domain_options'] &&
        $scope.searchParams['mail_domain_options'] != 'All')

      # If not selected either white label, firm, or email domain do not allow export
      if !(has_WLP_selection || has_firm_selection || has_domain_selection)
        $scope.showExportErrorMessage = true
      else
        UserManagementService.userExport($scope.searchParams).then (d) ->
          $scope.job_submission_status = d.msg

      $window.scrollTo(0, 0)

    init = ->
      $scope.getWhiteLabelPartners()
      $scope.getAccreditationOptions()
      $scope.us_states = FormDataService.usStates

    FullWidth.useFullWidthScreen()

    $scope.$on '$destroy', ->
      FullWidth.removeFullWidthScreen()

    init()
