angular.module('services').factory 'IcnIdentity', ($window) ->
  class Identity
    constructor: ->
      if !$window.icnBootstrap
        request = new XMLHttpRequest()
        request.open("GET", "#{window.partnerProxyAPIPrefix}/api/v1/users/angular_bootstrap_identity.json", false)
        request.send(null)
        if request.status == 200
          $window.icnBootstrap = JSON.parse(request.response)

      @data = $window.icnBootstrap
      @user = @data?.user

    unaccreditedInvestor: ->
      !@user.accredited && (@user.isQP || @user.isSfo)

    unaccreditedRia: ->
      !@user.accredited && @user.isRia

    check: (ability) ->
      @user[ability]

    isInterestedParty: ->
      @user.isInterestedParty

    onlyInterestedParty: ->
      @user.onlyInterestedParty

    isInvestor: ->
      @user.isInvestor

    isIndividualInvestor: ->
      @user.isAi || @user.isAicl || @user.isQp || @user.isQpcl || @user.isSfo

    isNonRiaInvestor: ->
      @user.isNonRiaInvestor

    canAccessBnyApi: ->
      @user.canAccessBnyApi

    canAddExistingProspectInvestments: ->
      @user.canAddExistingProspectInvestments

    canAddNewProspectInvestments: ->
      @user.canAddNewProspectInvestments

    canSeeSubscriptionsDashboard: ->
      @user.canSeeSubscriptionsDashboard

    canAdministerPeFunds: ->
      @user.canAdministerPeFunds

    canAdministerHedgeFunds: ->
      @user.canAdministerHedgeFunds

    canAssignInvestorsTransfers: ->
      @user.canAssignInvestorsTransfers

    canAddNewInvestors: ->
      @user.canAddNewInvestors

    canInviteSavedInvestors: ->
      @user.canInviteSavedInvestors

    canSetSubscriptionValue: ->
      @isInvestor() || @user.canSetSubscriptionValue

    canSendSubscription: ->
      (@isInvestor() && !@user.isRia) || @user.canSendSubscription

    canUploadSignedSubscription: ->
      @isInvestor() || @user.canUploadSignedSubscription

    canUploadAmlDocuments: ->
      @isInvestor() ||  @user.canUploadAmlDocuments

    canCancelInvestment: ->
      @isInvestor() || @user.canCancelInvestment

    canCancelApprovedInvestment: ->
      @user.canCancelApprovedInvestment

    canViewEventsHistory: ->
      @user.canViewEventsHistory

    canViewApprovalHistory: ->
      @user.canViewApprovalHistory

    canSendReminder: ->
      @user.canSendReminder && (
        @isRia() ||
        @isBranchManager() ||
        @isPafAdmin()
      )

    canHaveInvestments: ->
      @user.canHaveInvestments

    canCreateNewProfile: ->
      @user.canCreateNewProfile

    canDeleteInvestments: ->
      @user.canDeleteInvestments

    canInitiateAdditionalSubscription: ->
      @user.canInitiateAdditionalSubscription

    canInitiateRedemption: ->
      @user.canInitiateRedemption

    canDownloadSubscription: ->
      if @isRia
        @user.canDownloadSubscription
      else
        @isInvestor() || @user.canDownloadSubscription

    canResetSubscription: ->
      @isInvestor() || @user.canResetSubscription

    canEditInvestorProfiles: ->
      @user.canEditInvestorProfiles

    canManageInvestors: ->
      @user.canManageInvestors || @user.canEditInvestorProfiles

    canAccessTransfers: ->
      @user.canAccessTransfers

    canManageCapitalEvents: ->
      @user.canManageCapitalCalls && @user.canManageDistributions

    canViewCapitalEvents: ->
      @user.canViewCapitalEvents

    canImportCapitalEvents: ->
      @user.canImportCapitalEvents

    hasEditInvestorProfilesPermission: ->
      @user.canViewInvestorProfiles && @user.hasEditInvestorProfilesPermission

    canViewInvestorProfiles: ->
      @user.canViewInvestorProfiles

    canRevertApprovedInvestments: ->
      @user.canRevertApprovedInvestments

    canEditFundCustodians: ->
      @user.canEditFundCustodians

    canApproveDocumentReview: ->
      @user.canApproveDocumentReview

    canEditFormsReadonlyFields: ->
      @user.canEditFormsReadonlyFields

    canSyncDataToDlt: ->
      @user.canSyncDataToDlt

    canApproveComplianceReview: ->
      @user.canApproveComplianceReview

    canUploadPcapReport: ->
      @user.canUploadPcapReport

    defaultPage: ->
      @user.default_page

    isRia: ->
      @user.isRia

    isAi: ->
      @user.isAi

    isQp: ->
      @user.isQP

    isQpcl: ->
      @user.isQpcl

    isAicl: ->
      @user.isAicl

    isSalesManager: ->
      @user.isSalesManager

    isSalesManagerAdmin: ->
      @user.isSalesManagerAdmin

    isPafAdmin: ->
      @user.isPafAdmin

    isInvestorRelations: ->
      @user.isInvestorRelations

    isAdminOrIr: ->
      @isPafAdmin() || @isInvestorRelations()

    isAdminAndIr: ->
      @isPafAdmin() && @isInvestorRelations()

    isPafApprover: ->
      @user.isPafApprover

    isPortalApprover: ->
      @user.isPortalApprover

    isUserManager: ->
      @user.isUserAdmin

    isDocumentReviewer: ->
      @user.isDocumentReviewer

    isComplianceApprover: ->
      @user.isComplianceApprover

    isAmlApprover: ->
      @user.isAmlApprover

    isExternalApprover: ->
      @user.isExternalApprover

    isSfo: ->
      @user.isSfo

    isHomeOffice: ->
      @user.isHomeOffice

    isBranchManager: ->
      @user.isBranchManager

    isDocumentManager: ->
      @user.isDocumentManager

    firmId: ->
      @user.firm_id

    userId: ->
      @user.id

    isLimitedForPaf: (id) ->
      _.indexOf(@user.limited_paf_ids, id) > -1

    isWhitelistedForPaf: (id) ->
      _.indexOf(@user.pafs_whitelisted_for_firm, id) > -1

    noWhiteLabelInvestmentLimitations: ->
      !(@isWhiteLabel() && @whiteLabelPartner().limited_qpcl_paf_access && @user.isQpcl)

    showAccountNum: ->
      @isWhiteLabel() && @whiteLabelPartner().show_account_num_on_ip_list &&
      (@user.isRia || @user.isBranchManager)

    showSendInvestorRegistration: ->
      @whiteLabelPartner().show_send_investor_registration

    showAccountTypeInAddInvestorModal: ->
      @whiteLabelPartner().account_type_investor_profile

    customInstructionalText: (key) ->
      if(key == "step_two")
        key = "step_two_ria" if @isRia()
        key = "step_two_qp" if !@isRia()
      @whiteLabelPartner()?.instructional_text?[key]

    accredited: ->
      @data.user?.accredited

    authenticated: ->
      @user != null

    pendingMfaRegistration: ->
      @data.pending_mfa_registration

    setPendingMfaRegistration: (value) ->
      @data.pending_mfa_registration = value

    needAssistanceOverride: ->
      @data.white_label_partner.need_assistance_override

    loginDestination: ->
      if @whiteLabelPartner().hasOwnProperty('override_login_url') &&
      !!@whiteLabelPartner().override_login_url != false
        @whiteLabelPartner().override_login_url
      else
        "/login"

    enableRedemptions: ->
      @whiteLabelPartner().enable_redemptions || @whiteLabelPartner().id == 0

    canSetShareClass: ->
      @user.canUpdateShareClass

    isImposter: ->
      @user.is_imposter

    isAbleToEditInvestment: ->
      (@user.isDocumentReviewer || @user.isHomeOffice) && @user['canEditInvestment']

    isRiaOrBranchManager: ->
      @isRia() || @isBranchManager()

    isAbleToExportDocument: ->
      @user['canExportData'] &&
      (@isPafAdmin() || @isInvestorRelations() || @isExternalApprover() || @isComplianceApprover() || @isHomeOffice() ||
      @isDocumentReviewer() || @isBranchManager())

    canAddSubReviewNotes: ->
      @user.canAddSubReviewNotes

    canHaveSubReviewNotes: ->
      @user.canAddSubReviewNotes || @user.canViewSubReviewNotes

    canUbsRefreshData: ->
      @user.canUbsRefreshData

    canGenerateTaxReport: ->
      @isPafAdmin() && @user.canGenerateTaxReport

    sequenceSignersRequired: ->
      @whiteLabelPartner()?.sequence_signers_required

    wlpSlug: ->
      @whiteLabelPartner()?.slug

    hideFundingTooltip: ->
      @whiteLabelPartner()?.hide_funding_tooltip || false

    hideInterestedPartyRegistration: ->
      @whiteLabelPartner()?.hide_interested_party_registration || false

    hideThirdPartyVendors: ->
      @whiteLabelPartner()?.hide_third_party_vendors || false

    resendSubdocOnIncreaseOnly: ->
      @whiteLabelPartner()?.resend_subdoc_on_increase_only || false

    canEditSubReviewNotes: ->
      !@whiteLabelPartner().disable_editing_sub_review_notes

    canEditShareClass: ->
      @user.canEditShareClass

    canTeamManagementViewTeams: ->
      @user.canTeamManagementViewTeams

    canTeamManagementCreateDeleteTeams: ->
      @user.canTeamManagementCreateDeleteTeams

    canSendToFidelity: ->
      @user.canSendToFidelity

    canEditApprovedRequiredDocuments: ->
      (@user.isHomeOffice || @user.isPafAdmin) && @user.canEditApprovedRequiredDocuments

    email: ->
      @user.email

    canAddExternalNotes: ->
      @user.canAddExternalNotes && @user.isPafAdmin

    canReactLibraryTemplates: ->
      @user.canReactLibraryTemplates

    canLoadRedemptions: ->
      @check('canManageRedemptions') || @check('canManageRedemptionsDirectsOnly')

    isWhiteLabel: ->
      if @data.white_label_partner
        return @data.white_label_partner.id != 0
      else
        return false

    whiteLabelPartner: ->
      return @data.white_label_partner

    isPortal: ->
      @isWhiteLabel() && @data.white_label_partner.portal

    featureFlagEnabled: (flag, strict = false) ->
      val = @data.feature_flags[flag]
      return true if val && !strict
      return true if val == true
      false

    featureFlagDisabled: (flag) ->
      !@featureFlagEnabled(flag)

    mainContactLabel: ->
      @data.white_label_partner.main_contact_label

    environment: ->
      @data.env

  new Identity()
